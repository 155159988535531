<template>
  <div class="daping">
    <div class="lanBg" ></div>
    <div>
      <div style="height: 29px"></div>
      <div class="header">

        <div style="height: 19px"></div>
        <div class="dabiaoti" style="font-size: 23px">金药匙·云享药房</div>
      </div>
      <div class="content">
        <div class="login">
<div class="logintxt">
  <div class="item">
    <input class="" placeholder="请输入用户名" v-model="loginForm.username"></input>
  </div>
  <div class="item" style="padding-top: 12px">
    <el-input style="width: 307px;" show-password type="password" class="" placeholder="请输入密码" v-model="loginForm.password"></el-input>
  </div>
  <div class="item" style="padding-top: 5px">
    <input class="" placeholder="请输入手机号" v-model="loginForm.phone"></input>
  </div>
  <div class="item" style="padding-top: 5px" >
    <input class="" style="width: 200px" placeholder="请输入验证码" v-model="loginForm.authCode" @keyup.enter="login"></input><span style="color: #0895EE" v-if="djsSecond<10">{{djsSecond}}秒重新获取</span><span style="color: #0895EE;cursor: pointer" @click="startDjs()" v-if="djsSecond==10">获取验证码</span>
  </div>
</div>
<div style="text-align: center;margin-bottom: 10px">
  <el-checkbox v-model="checked">记住密码</el-checkbox>
</div>
<div style="text-align: center"><button class="btn" @click="login">登录</button></div>
        </div>
      </div>

    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep .el-input__inner{
  text-align: left;
  font-size: 12px;
}
</style>
<style scoped>
.logintxt{padding-top: 126px;padding-left: 246px}
.logintxt input{width: 303px;height: 50px;BACKGROUND-COLOR: transparent;
  border: 0;
outline: none;
  color: #fff;

}
.logintxt .item{margin-bottom: 25px}
.btn{background-color: #0895EE;
width: 300px;height: 50px;
border-radius: 25px;
border: 0px;color: #fff}
.login{width: 772px;height: 592px;
  margin: 0 auto;
  margin-top: 100px;
background: url("../assets/login.png")}
.daping{
  width: 1920px;
  height: 1080px;
  background: url("../assets/lgbg.jpg");
}
</style>
<script>
import { sha256, sha224 } from 'js-sha256';
import axios from "axios";
import {userInfo} from "@/api/yaojian";
import store from "@/store";
export default {
  name: 'login',
  data () {
    return {
      checked: false,
      inter:0,
      djsSecond:10,
      loginData:{},
      loginForm:{

      },
      url:""
    }
  },
  created() {
    this.url=this.$route.query.url
    if(localStorage.login){
      let login=JSON.parse(localStorage.login)
      this.checked=true
      this.loginForm.username=login.username
      //this.loginForm.password=login.password
      this.$set(this.loginForm, 'password', login.password)
      this.loginForm.phone=login.phone
    }
  },
  mounted(){

  },
  methods:{

    checkSms(){
      let post={
        "code": this.yzm,
        "phone": this.post.registerPhone
      }
      axios.post(`/sms/code/check`, post).then(res => {
        if(res===true){
          this.regFuc()
        }else{
          this.$message.error("验证码错误")
        }
      }).catch(error => {
        console.log('error', error)
      })
    },
    sendSms(){

      let that=this
      axios.get(`/system/user/sms/code?phone=`+this.loginForm.phone, {}).then(res => {
        if(res.data.code==200){
          that.inter = setInterval(()=>{that.djs()}, 1000)
          that.$message.success("验证码已发送")
        }else{
          that.$message.success(res.data.msg)
        }
      }).catch(error => {
        console.log('error', error)
      })
    },
    startDjs(){
      this.sendSms()
      this.isDisabled=true

    },
    djs(){
      this.djsSecond--
      if(this.djsSecond <= 0) {
        this.djsSecond=10
        this.isDisabled=false
        clearInterval(this.inter);
      }

    },
    async userInfo(){
      let that=this
      let host=window.location.hostname
      let rs=await userInfo()
           if(rs.code==200){
           localStorage.id=rs.data.regionCode
            that.$store.commit('SET_cityId',rs.data.regionCode)
            let userData = {
              avatar: rs.data.avatar,
              nickName: rs.data.nickName,
              regionCode: rs.data.regionCode,
              regionName: rs.data.regionName,
              type: rs.data.type
            }
            if(host=="dp.yaojian.xusos.com"){
              that.$Cookies.set('userInfo',JSON.stringify(userData),{domain:'yaojian.xusos.com',expires: 100})
            }else{
              that.$Cookies.set('userInfo',JSON.stringify(userData))
            }
            that.$router.push("/")
      }
    },
     async login(){
let that=this
        let data=JSON.parse(JSON.stringify(this.loginForm))
        data.password=sha256(data.password)
        if(this.checked){
          localStorage.login=JSON.stringify(this.loginForm)
        }else{
          localStorage.login=""
        }
        let host=window.location.hostname

            axios.post('/login', data)
                .then(function (response) {
                  if(response.data.code==200){
                    that.loginData=response.data.data
                    if(host=="dp.yaojian.xusos.com"){
                      that.$Cookies.set('AuthorizationToken',response.data.data.Authorization,{domain:'yaojian.xusos.com',expires: 7})
                      that.$Cookies.set('identifier',response.data.data.identifier,{domain:'yaojian.xusos.com',expires: 7})
                    }else{
                      that.$Cookies.set('AuthorizationToken',response.data.data.Authorization)
                      that.$Cookies.set('identifier',response.data.data.identifier)
                    }
                   that.userInfo()
                    //获取用户信息
                /*    axios.get("/system/user/info", {
                      headers:{
                        Authorization: response.data.data.Authorization
                      }},).then(function (rs){
                      localStorage.id=rs.data.data.regionCode
                      that.$store.commit('SET_cityId',rs.data.data.regionCode)
                      let userData = {
                        avatar: rs.data.data.avatar,
                        nickName: rs.data.data.nickName,
                        regionCode: rs.data.data.regionCode,
                        regionName: rs.data.data.regionName,
                        type: rs.data.data.type
                      }
                      if(host=="dp.yaojian.xusos.com"){
                        that.$Cookies.set('userInfo',JSON.stringify(userData),{domain:'yaojian.xusos.com',expires: 100})
                      }else{
                        that.$Cookies.set('userInfo',JSON.stringify(userData))
                      }
                      that.$router.push("/")
                    })*/



                  }else{
                    that.$message.error(response.data.msg)

                  }

                })
      }
  }
}
</script>
<style>
.el-input__inner{
  background: transparent;
  border: 0;
  color: #0895ee;
  font-size: 30px;
  text-align: center;
}
</style>
<style scoped>
.jsq_top{font-size: 20px;color: white;margin-bottom: 14px}
.jsq{display: flex;flex-direction: row;font-size: 40px;color: #07DBFF}
.jsq span{
  background: url('../assets/szms/jsq.png') no-repeat;
  text-align: center;
  width: 49px;
  height: 64px;
  line-height: 64px;
  display: block;
  margin-right: 6px;
}
.jkjt_list{
  width: 410px; margin: 0 auto;
  color: white;
  font-size: 20px;
}
.jkjt_list_item{
  border-radius: 25px;
  background-color: #123E6B;
  height: 50px;
  margin-top: 18px;
  line-height: 50px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.cer_sz{
  font-size: 38px;
  color: white;
  padding-top: 143px;
}
.cer_wz{
  font-size: 18px;
  color: white;
}
.cercle2{
  width: 370px;height: 370px;
  margin: 0 auto;
  background: url('../assets/szqy/cercle2.png') no-repeat;
  text-align: center;
  margin-top: 34px;
}
.cercle{
  width: 362px;height: 362px;
  margin: 0 auto;
  background: url('../assets/szqy/cercle.png') no-repeat;
  text-align: center;
  margin-top: 34px;
}
.you_btn{
  background: url("../assets/btn.png") no-repeat left;
  height: 58px;
  width: 146px;
  color: #0895EE;
  text-align: center;
  position: absolute;
  line-height: 58px;
  right: 48px;
  cursor: pointer;
}
.yjtz{
  background: url("../assets/bq.png") no-repeat left;
  height: 40px;
  width: 342px;
  color: #5BFCF4;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 15px;
}
.search_input{height: 41px; width: 728px;background-color: transparent;border: 0;text-align: center;color: #0895EE;font-size: 28px}
.search{width: 773px;height: 60px; background: url("../assets/s.png"); margin: 0 auto}
.kuang_left1{
  background: url("../assets/szms/left1.png") no-repeat center;
  height: 212px;
}
.kuang_left2{
  background: url("../assets/szms/left2.png") no-repeat center;
  height: 342px;
}
.kuang_left3{
  background: url("../assets/szms/left3.png") no-repeat center;
  height: 396px;
}

.kuang_you{
  background: url("../assets/szms/right.png") no-repeat center;
  height: 950px;
}
.kuang_center1{
  background: url("../assets/szms/center.png") no-repeat center;
  height: 844px;
  margin-top: 16px;

}
.kuang_center2{
  background: url("../assets/szqy/center2.png") no-repeat center;
  height: 502px;

}
.kuang_you2{
  background: url("../assets/szqy/right2.png") no-repeat center;
  height: 503px;
}
.kuang_b{
  background: url("../assets/kuang_b.png") no-repeat center;
  height: 683px;
}
.kuang_tit{
  padding-top: 23px;
  padding-left: 50px;
  height: 40px;
  color: #5BFCF4;
  font-size: 20px;
}
.main_content_right{float: right;width: 502px}
.main_content_left{float: left;width: 502px}
.main_content_center{width: 923px;margin: 0 auto}
.dabiaoti{
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
}

</style>
