<template>
    <div class="enterprise">
        <div class="enterprise-content">
            <!-- 头部 -->
          <yun-header />
            <div class="enterprice-body">
                <div class="left">
                    <!-- 企业经营资质动态信息监测 -->
                    <div class="enterprice-monitor">
                        <!-- 标题 -->
                        <div class="enterprice-monitor-header">
                            <span> 药店经营资质动态信息监测</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!-- 药品经营许可证  执业药师注册证  健康证-->
                        <div class="enterprice-monitor-type">
                            <div class="enterprice-monitor-left commont">
                                <div class="commont-echart" ref="leftEchart"></div>
                                <span>药品经营许可证</span>
                            </div>
                            <div class="enterprice-monitor-center commont">
                                <div class="commont-echart" ref="centerEchart"></div>
                                <span>执业药师注册证</span>
                            </div>
                            <div class="enterprice-monitor-right commont">
                                <div class="commont-echart" ref="rightEchart"></div>
                                <span>健康证</span>
                            </div>
                        </div>
                        <!-- 表格 -->

                        <div class="enterprice-table">
                            <table>
                                <tr class="first">
                                    <td style="width: 68px">序号</td>
                                    <td style="width:191px;text-align: left">药店名称</td>
                                    <td style="width: 150px">证件名称</td>
                                    <td style="width: 130px">有效期至</td>
                                    <td style="width: 130px">提醒状态</td>
                                </tr>
                            </table>
                          <div style="height:760px;overflow: hidden;">
                          <vue-seamless-scroll
                              :data="tableList"
                              :class-option="defaultOption"
                          >
                          <table>
                            <tr class="second" v-for="(item, index) in tableList" :key="index">
                              <td style="width:68px">{{ index + 1 }}</td>
                              <td  style="width:191px;text-align: left">{{ item.storeName }}</td>
                              <td style="width: 150px">药品经营许可证</td>
                              <td style="width: 130px">{{ item.validDate2 }}</td>
                              <td style="color:#1DCE3A;width: 130px">已提醒</td>
                            </tr>
                          </table>
                          </vue-seamless-scroll>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="equipment">
                        <!-- 标题 -->
                        <div class="equipment-monitor-header">
                            <span> 药店设备动态信息监测</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!--视频监控设备运营情况 湿温度设备运营情况  -->
                        <div class="equipment-type">
                            <div class="equipment-left commont">
                                <div class="commont-echart" ref="surveillance"></div>
                                <span>视频监控设备运营情况</span>
                            </div>
                            <div class="equipment-right commont">
                                <div class="commont-echart" ref="humidity"></div>
                                <span>湿温度设备运营情况</span>
                            </div>
                        </div>
                        <!-- 表格 -->

                        <div class="equipment-table">
                            <table>
                                <tr class="first">
                                    <td style="width: 68px;text-align: center">序号</td>
                                    <td style="width:220px;text-align: left">药店名称</td>
                                    <td >设备名称</td>
                                    <td style="width: 130px">状态</td>

                                    <td style="width: 130px">提醒状态</td>
                                </tr>
                            </table>
                          <div style="height:760px;overflow: hidden;">
                            <vue-seamless-scroll
                                :data="tableList"
                                :class-option="defaultOption"
                            >
                            <table>
                                <tr
                                    class="second"
                                    v-for="(item, index) in wsdData.list"
                                    :key="index"
                                >
                                    <td style="width: 68px;text-align: center">{{ index + 1 }}</td>
                                    <td style="line-height:30px;width:220px;text-align: left">{{ item.storeName }}</td>
                                    <td >{{ item.deviceName }}</td>
                                    <td style="width: 130px">
                                        <span style="" v-if="item.deviceStatus == 0">未运行</span>
                                        <span style="color:#FE4018" v-if="item.deviceStatus ==1">离线</span>
                                        <span style="" v-if="item.deviceStatus == 2">在线</span>
                                        <span style="" v-if="item.deviceStatus == 3">报停</span>
                                    </td>

                                    <td style="color:#1DCE3A;width: 130px" >已提醒</td>
                                </tr>
                            </table>
                            </vue-seamless-scroll>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import Pager from "./components/pager";
import {licenceList,wsdList} from "@/api/yaojian"
import vueSeamlessScroll from "vue-seamless-scroll";
import yunHeader from "@/views/yunHeader";
export default {
    components: {
        Pager, vueSeamlessScroll,yunHeader
    },
    data() {
        return {
          wsdData:{},
            enterprice: null,
            surveillance: null, // 视频监控

            humidity: null, // 湿温度
            //企业经营资质动态信息监测
            tableList: [
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    riskname: "药品经营许可证",
                    foundTime: "2022-05-24  12:12:12",
                    tooltiptime: "2022-03-25",
                    stadus: 1,
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    riskname: "药品经营许可证",
                    foundTime: "2022-05-24  12:12:12",
                    tooltiptime: "2022-03-25",
                    stadus: 2,
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    riskname: "药品经营许可证",
                    foundTime: "2022-05-24  12:12:12",
                    tooltiptime: "2022-03-25",
                    stadus: 2,
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    riskname: "药品经营许可证",
                    foundTime: "2022-05-24  12:12:12",
                    tooltiptime: "2022-03-25",
                    stadus: 1,
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    riskname: "药品经营许可证",
                    foundTime: "2022-05-24  12:12:12",
                    tooltiptime: "2022-03-25",
                    stadus: 1,
                },
            ],
            //  企业设备动态信息监测
            equipmentLsit: [
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    riskname: "湿温度设备",

                    stadus: 1,
                    tooltiptime: "2022-03-25",
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    riskname: "湿温度设备",
                    stadus: 1,
                    tooltiptime: "2022-03-25",
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    riskname: "湿温度设备",

                    stadus: 1,
                    tooltiptime: "2022-03-25",
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    riskname: "湿温度设备",

                    stadus: 1,
                    tooltiptime: "2022-03-25",
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    riskname: "湿温度设备",

                    stadus: 1,
                    tooltiptime: "2022-03-25",
                },
            ],
            typeList: [
                { value: 3, name: "损坏报修" },
                { value: 2, name: "报停" },
                { value: 5, name: "不在线" },
                { value: 90, name: "正常" },
            ],
            enterpriceLeftList: [
                {
                    value: 100,
                    name: "正常",
                },
                {
                    value: 0,
                    name: "将过期",
                },
            ],
          zyysPie: [
            {
              value: 95.3,
              name: "正常",
            },
            {
              value:4.7,
              name: "将过期",
            },
          ],
          jkzPie: [
            {
              value: 98.6,
              name: "正常",
            },
            {
              value:1.4,
              name: "将过期",
            },
          ],
          wsdPie: [
            { value: 7.74, name: "损坏报修" },
            { value: 6.55, name: "报停" },
            { value: 14.88, name: "不在线" },
            { value: 70.83, name: "正常" },
          ],
        };
    },
  computed:{
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
    mounted() {
        this.enterpriceLeft();
        this.enterpriceCenter();
        this.enterpriceRight();
        this.equipment();
        this.humidityEchart();
        this.getZzlist()
        this.WsdList()
    },
    methods: {
      WsdList(){
        let post={
          page:1,
          count:100
        }
        wsdList(post).then(x=>{
           this.wsdData=x.data
        })
      },
       getZzlist(){
        let post={page:1,count:100,type:10,status:20}
        licenceList(post).then(rs=>{
          this.tableList=rs.data.list
          this.tableList.map(x=>{
            if(x.validDate){
              x.validDate2=x.validDate.substring(0,10)
            }else{
              x.validDate2=""
            }

          })
        })

      },
        enterpriceLeft() {
            this.enterprice = echarts.init(this.$refs.leftEchart);
            const option = {
                color: ["#0078fa", "#fbc902"],
                tooltip: {
                    trigger: "item",

                    textStyle: {
                        fontWeight: "normal",
                        fontSize: 11,
                    },
                },
                legend: {
                    bottom: "0",
                    orient: "horizontal",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        rich: {
                            a: {
                                display: "block",
                                width: 60,
                                fontSize: 18,
                                color: "#fff",
                            },
                            b: {
                                fontSize: 18,
                                color: "#fff",
                            },
                        },
                    },
                    formatter: (name) => {
                        const currentIndex = this.enterpriceLeftList.findIndex(
                            (item) => item.name == name
                        );
                        return `{a|${name}}{b|${this.enterpriceLeftList[currentIndex].value}%}`;
                    },
                },
                series: [
                    {
                        name: "药品经营许可证",
                        type: "pie",
                        selectedMode: "single",
                        radius: [0, "35%"],
                        center: ["50%", "45%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: this.enterpriceLeftList,
                    },
                    {
                        name: "药品经营许可证",
                        type: "pie",
                        selectedMode: "single",
                        radius: ["45%", "60%"],
                        center: ["50%", "45%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: this.enterpriceLeftList,
                    },
                ],
            };
            this.enterprice.setOption(option);
        },
        enterpriceCenter() {
            this.enterprice = echarts.init(this.$refs.centerEchart);
            const option = {
                color: ["#0078fa", "#fbc902"],
                tooltip: {
                    trigger: "item",

                    textStyle: {
                        fontWeight: "normal",
                        fontSize: 11,
                    },
                },
                legend: {
                    data: this.zyysPie,
                    bottom: "0",
                    orient: "horizontal",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        rich: {
                            a: {
                                display: "block",
                                width: 60,
                                fontSize: 18,
                                color: "#fff",
                            },
                            b: {
                                fontSize: 18,
                                color: "#fff",
                            },
                        },
                    },
                    formatter: (name) => {
                        const currentIndex = this.zyysPie.findIndex(
                            (item) => item.name == name
                        );
                        return `{a|${name}}{b|${this.zyysPie[currentIndex].value}%}`;
                    },
                },
                series: [
                    {
                        name: "执业药师注册证",
                        type: "pie",
                        selectedMode: "single",
                        radius: [0, "35%"],
                        center: ["50%", "45%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: this.zyysPie,
                    },
                    {
                        name: "执业药师注册证",
                        type: "pie",
                        selectedMode: "single",
                        radius: ["45%", "60%"],
                        center: ["50%", "45%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: this.zyysPie,
                    },
                ],
            };
            this.enterprice.setOption(option);
        },
        enterpriceRight() {
            this.enterprice = echarts.init(this.$refs.rightEchart);
            const option = {
                color: ["#0078fa", "#fbc902"],
                tooltip: {
                    trigger: "item",

                    textStyle: {
                        fontWeight: "normal",
                        fontSize: 11,
                    },
                },
                legend: {
                    data: this.jkzPie,
                    bottom: "0",
                    orient: "horizontal",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        rich: {
                            a: {
                                display: "block",
                                width: 60,
                                fontSize: 18,
                                color: "#fff",
                            },
                            b: {
                                fontSize: 18,
                                color: "#fff",
                            },
                        },
                    },
                    formatter: (name) => {
                        const currentIndex = this.jkzPie.findIndex(
                            (item) => item.name == name
                        );
                        return `{a|${name}}{b|${this.jkzPie[currentIndex].value}%}`;
                    },
                },
                series: [
                    {
                        name: "健康证",
                        type: "pie",
                        selectedMode: "single",
                        radius: [0, "35%"],
                        center: ["50%", "45%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: this.enterpriceLeftList,
                    },
                    {
                        name: "健康证",
                        type: "pie",
                        selectedMode: "single",
                        radius: ["45%", "60%"],
                        center: ["50%", "45%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: this.jkzPie,
                    },
                ],
            };
            this.enterprice.setOption(option);
        },
        // 视频监控设备运营情况
        equipment() {
            this.surveillance = echarts.init(this.$refs.surveillance);
            const colorList = {
                损坏报修: "#C41DF3",
                报停: "#FBC902",
                不在线: "#0096FF",
                正常: "#00D7FE",
            };
            const option = {
                color: ["#C41DF3", "#FBC902", "#0096FF", "#00D7FE"],
                legend: {
                    orient: "vertical",
                    right: 0,
                    top: "10%",
                    left: "55%",
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 20,
                    textStyle: {
                        rich: {
                            a: {
                                display: "block",
                                width: 100,
                                color: "#FFFFFF",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            b: {
                                color: "#C41DF3",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            c: {
                                color: "#FBC902",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            d: {
                                color: "#0096FF",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            e: {
                                color: "#00D7FE",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                        },
                    },
                    formatter: (name) => {
                        const currentIndex = this.typeList.findIndex((item) => item.name == name);
                        if (name == "损坏报修") {
                            return `{a|${name}}{b|${this.typeList[currentIndex].value}%}`;
                        } else if (name == "报停") {
                            return `{a|${name}}{c|${this.typeList[currentIndex].value}%}`;
                        } else if (name == "不在线") {
                            return `{a|${name}}{d|${this.typeList[currentIndex].value}%}`;
                        } else {
                            return `{a|${name}}{e|${this.typeList[currentIndex].value}%}`;
                        }
                    },
                },
                series: [
                    {
                        name: "视频监控设备运营情况 ",
                        type: "pie",
                        radius: ["40%", "70%"],
                        center: ["35%", "50%"],
                        label: {
                            show: false,
                            position: "center",
                        },
                        data: this.typeList,
                    },
                ],
            };
            this.surveillance.setOption(option);
        },
        humidityEchart() {
            this.humidity = echarts.init(this.$refs.humidity);
            const colorList = {
                损坏报修: "#C41DF3",
                报停: "#FBC902",
                不在线: "#0096FF",
                正常: "#00D7FE",
            };
            const option = {
                legend: {
                    orient: "vertical",
                    right: 0,
                    top: "10%",
                    left: "55%",
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 20,
                    textStyle: {
                        rich: {
                            a: {
                                display: "block",
                                width: 100,
                                color: "#FFFFFF",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            b: {
                                color: "#C41DF3",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            c: {
                                color: "#FBC902",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            d: {
                                color: "#0096FF",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            e: {
                                color: "#00D7FE",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                        },
                    },
                    formatter: (name) => {
                        const currentIndex = this.wsdPie.findIndex((item) => item.name == name);
                        if (name == "损坏报修") {
                            return `{a|${name}}{b|${this.wsdPie[currentIndex].value}%}`;
                        } else if (name == "报停") {
                            return `{a|${name}}{c|${this.wsdPie[currentIndex].value}%}`;
                        } else if (name == "不在线") {
                            return `{a|${name}}{d|${this.wsdPie[currentIndex].value}%}`;
                        } else {
                            return `{a|${name}}{e|${this.wsdPie[currentIndex].value}%}`;
                        }
                    },
                },
                series: [
                    {
                        name: "湿温度设备运营情况 ",
                        type: "pie",
                        radius: ["40%", "70%"],
                        center: ["35%", "50%"],
                        label: {
                            show: false,
                            position: "center",
                        },
                        data: this.wsdPie,
                        itemStyle: {
                            color: (arg) => {
                                console.log(arg);
                                return colorList[arg.name];
                            },
                        },
                    },
                ],
            };
            this.humidity.setOption(option);
        },
    },
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.enterprise {
    width: 1920px;
    background-image: url("../assets/regulation/bg.jpg");
    height: 1080px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .enterprise-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .enterprise-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 65px;
            width: 100%;
            background-image: url("../assets/regulation/bt.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .enterprise-header-back {
                cursor: pointer;
                position: absolute;
                left: 46px;
                top: 60%;
                transform: translateY(-50%);
                span {
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #00d7fe;
                    line-height: 26px;
                    margin: 0 30px 0 10px;
                }
                img {
                    width: 23px;
                    height: 19px;
                }
            }
            .enterprise-header-title {
                font-size: 26px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 26px;
            }
        }
        .enterprice-body {
            flex: 1;
            display: flex;
            gap: 20px;
            margin-top: 15px;
            padding: 0 35px;
            .left {
                flex: 1;
                .enterprice-monitor {
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    height: 959px;
                    background-image: url("../assets/enterprice/左-无圆点.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .enterprice-monitor-header {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            margin-right: 20px;
                            letter-spacing: 6px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .enterprice-monitor-type {
                        display: flex;
                        margin-top: 40px;
                        .commont {
                            flex: 1;
                            height: 216px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: center;
                            .commont-echart {
                                height: 193px;
                                width: 100%;
                            }
                            span {
                                font-size: 22px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #00d7fe;
                                line-height: 26px;
                                margin-top: 20px;
                            }
                        }
                    }
                    .enterprice-table {
                        flex: 1;
                        margin-top: 30px;
                        overflow-y: hidden;
                        table {
                            //height: 100%;
                            width: 100%;
                            border-collapse: collapse;
                            .first {
                                height: 40px;
                                background-image: url("../assets/regulation/表头.png");
                                text-align: center;
                                td {
                                    line-height: 40px;
                                    font-size: 18px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                }
                            }
                            .second {
                                text-align: center;
                                height: 80px;
                                &:nth-child(2n + 1) {
                                    background: url("../assets/regulation/表单.png");
                                    background-size: 100% 100%;
                                    background-repeat: no-repeat;
                                }
                                td {
                                    height: 80px;
                                    font-size: 18px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
            .right {
                flex: 1;
                .equipment {
                    display: flex;
                    flex-direction: column;
                    height: 959px;
                    padding: 20px;
                    background-image: url("../assets/enterprice/右-无圆点.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .equipment-monitor-header {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            margin-right: 20px;
                            letter-spacing: 6px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .equipment-type {
                        display: flex;
                        margin-top: 40px;
                        .commont {
                            flex: 1;
                            height: 216px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: center;
                            .commont-echart {
                                height: 193px;
                                width: 100%;
                            }
                            span {
                                font-size: 24px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #00d7fe;
                                line-height: 26px;
                            }
                        }
                    }
                    .equipment-table {
                        flex: 1;
                        margin-top: 30px;
                        overflow-y: hidden;
                        table {
                            //height: 100%;
                            width: 100%;
                            border-collapse: collapse;
                            .first {
                                height: 40px;
                                background-image: url("../assets/regulation/表头.png");
                                text-align: center;
                                td {
                                    line-height: 40px;
                                    font-size: 18px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                }
                            }
                            .second {
                                text-align: center;
                                height: 80px;
                                &:nth-child(2n + 1) {
                                    background: url("../assets/regulation/表单.png");
                                    background-size: 100% 100%;
                                    background-repeat: no-repeat;
                                }
                                td {
                                    height: 80px;
                                    font-size: 18px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
