<template>
    <div class="main">
        <!--      <div v-if="locId!=$store.state.cityId" style="color: #FFFFFF;border-radius: 4px;width: 100px;cursor: pointer;z-index: 10;top: 38px;left:17px;position: absolute" @click="back()">返回</div>-->
        <yun-header />
        <div class="body">
            <div class="body_left">
                <div class="body_left1">
                    <div class="title" @click="open('/yunMedicine')">
                        <span class="jt hand"><img style="width:28px;height:28px" src="../assets/yun/home/jt.png" />
                        </span>
                        便捷买药
                    </div>
                    <div class="body_left1_1">
                        <div style="display:flex;gap:10px; justify-content: space-between;">
                            <div class="body_left-tree" @click="open('/yunSanji')">
                                <div style="float: right;font-size:30px" class="bai">
                                    {{ treeLevelTotal - nofound }}
                                </div>
                                <div>三级买药</div>
                            </div>
                            <div class="body_left-tree" @click="$refs.childDialog.openTsrq()">
                                <div style="float: right;font-size:30px" class="bai">1035</div>
                                <div>代办购药</div>
                            </div>
                        </div>
                    </div>
                    <div class="body_left1_2">
                        <div class="item" @click="$refs.childDialog.openSanji(1)">
                            <div class="item1">{{ level1 }}</div>
                            <div class="item2">{{ (level1 / treeLevelTotal * 100).toFixed(2) + '%' }}</div>
                            <div class="item3">一级（县域）▶</div>
                        </div>
                        <div class="item hand" @click="$refs.childDialog.openSanji(2)">
                            <div class="item1">{{ level2 }}</div>
                            <div class="item2">{{ (level2 / treeLevelTotal * 100).toFixed(2) + '%' }} </div>
                            <div class="item3 rlv">二级（市域）▶</div>
                        </div>
                        <div class="item hand" @click="$refs.childDialog.openSanji(3)">
                            <div class="item1">{{ level3 }}</div>
                            <div class="item2">{{ (level3 / treeLevelTotal * 100).toFixed(2) + '%' }}</div>
                            <div class="item3 rlv">三级（省域）▶</div>
                        </div>
                        <div class="item hand" @click="$refs.childDialog.openSanji(0)">
                            <div class="item1" style="color: #fff">{{ nofound }}</div>
                            <div class="item2" style="color: #fff">{{ (nofound / treeLevelTotal * 100).toFixed(2) + '%' }}</div>
                            <div class="item3">未找到▶</div>
                        </div>
                    </div>
                    <!-- <div class="body_left1_1">
                        <div
                            style="padding: 0 20px"
                            class="hand"
                            @click="$refs.childDialog.openTsrq()"
                        >
                            <div style="float: right;font-size:30px;font-size:30px" class="bai">
                                457
                            </div>
                            <div>特殊人群服务▶</div>
                        </div>
                    </div> -->
                </div>
                <div class="body_left2">
                    <div class="title" @click="open('/yunUse')">
                        <span class="jt hand"><img style="width:28px;height:28px" src="../assets/yun/home/jt.png" />
                        </span>
                        用药咨询
                    </div>
                    <div class="aqyy">
                        <div class="item hand" @click="$refs.childDialog.openPharmacistAsk()">
                            <div class="item1">
                                <!--                          <IOdometer
                              class="iOdometer"
                              :value="211"
                              format="d"
                          ></IOdometer>-->
                                {{ consultNumber }}
                            </div>
                            <div class="item2">药师咨询▶</div>
                        </div>
                        <div class="item hand" @click="$refs.childDialog.openAskOnlie()">
                            <div class="item1">
                                <!-- <timer-yun :end="3586" :type="25" /> -->
                                <IOdometer class="iOdometer" :value="onlineNumber" format="d"></IOdometer>
                            </div>
                            <div class="item2">在线问诊▶</div>
                        </div>


                    </div>
                </div>
                <div class="body_left3">
                    <div class="title hand" @click="open('/yunYaoshi')">
                        <span class="jt"><img style="width:28px;height:28px" src="../assets/yun/home/jt.png" /> </span>
                        药事服务
                    </div>
                    <div class="ysfwBlock">
                        <div class="item jian" @click="$refs.yunChild.open('msys')">
                            <div><img src="../assets/yun/home/left3-3.gif" /></div>
                            <div class="label">民生药事</div>
                            <div class="value">{{ ysfwzNum }}</div>
                        </div>
                        <div class="item" @click="$refs.yunChild.open('wdds')">
                            <div><img src="../assets/yun/home/left3-4.gif" /></div>
                            <div class="label">网订店送</div>
                            <div class="value">{{ wddsNum }}</div>
                        </div>
                        <div class="item jian" @click="$refs.yunChild.open('ssjd')">
                            <div><img src="../assets/yun/home/recovery.gif" /></div>
                            <div class="label">上山进岛</div>
                            <div class="value">{{ ssjdNum }}</div>
                        </div>
                        <div class="item" @click="$refs.yunChild.open('znyx')">
                            <div><img src="../assets/yun/home/left3-3.gif" /></div>
                            <div class="label">智能药箱</div>
                            <div class="value">{{ serveNUmber }}</div>
                        </div>
                    </div>
                    <!--                    <div style="padding-left: 43px;margin-top: 30px">
                        <div
                            style="display: flex;align-items: center;margin-top: 30px"
                            class="ysfw"
                            @click="openYizhan(1)"
                        >
                            <div class="item1">
                                <img
                                    width="75px;height:75px"
                                    src="../assets/yun/home/left3-3.gif"
                                />
                            </div>
                            <div class="item2">药事驿站服务▶</div>
                            <div class="item3">
                                &lt;!&ndash; <timer-yun :end="yizhanCi" :type="20" /> &ndash;&gt;
                                <IOdometer
                                    class="iOdometer"
                                    :value="14153"
                                    format="d"
                                ></IOdometer>
                            </div>
                        </div>
                        <div
                            style="display: flex;align-items: center;margin-top: 30px"
                            class="ysfw"
                            @click="$refs.childDialog.openSatisfaction('znyxtxfx')"
                        >
                            <div class="item1">
                                <img
                                    width="75px;height:75px"
                                    src="../assets/yun/home/left3-4.gif"
                                />
                            </div>
                            <div class="item2">智能药箱服务▶</div>
                            <div class="item3">
                                &lt;!&ndash; <timer-yun :end="1589" :type="35" /> &ndash;&gt;
                                <IOdometer
                                    class="iOdometer"
                                    :value="serveNUmber"
                                    format="d"
                                ></IOdometer>
                            </div>
                        </div>

                            <div
                            style="display: flex;align-items: center;margin-top: 30px"
                            class="ysfw"
                            @click="$router.push('/yunYaoshi')"
                        >
                            <div class="item1">
                                <img
                                    width="75px;height:75px"
                                    src="../assets/yun/home/recovery.gif"
                                />
                            </div>
                            <div class="item2"> 药品回收服务▶</div>
                            <div class="item3">
                                &lt;!&ndash; <timer-yun :end="1589" :type="35" /> &ndash;&gt;
                                <IOdometer
                                    class="iOdometer"
                                    :value="3367"
                                    format="d"
                                ></IOdometer>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
            <div class="body_center">
                <div class="fwzl">
                    <div class="fwzl-1" style="color: #00d7fe">服务总量</div>
                    <div class="zongNum">
                        <!--                        <fanpai :val="fwzl" />-->
                    </div>
                </div>
                <div class="kuang">
                    <div class="item hand" @click="$refs.childDialog.openStore()">
                        <div class="item1">{{ storeCount }}<span class="danwei">(家)</span></div>
                        <div class="item2">药店数量</div>
                    </div>
                    <div class="item hand" @click="$refs.childDialog.openPharmacist()">
                        <div class="item1">{{ pharmacistDataCount }}<span class="danwei">(人)</span></div>
                        <div class="item2">药师数量</div>
                    </div>
                    <div class="item hand">
                        <div class="item1">{{ msssNum }}<span class="danwei">(家)</span></div>
                        <div class="item2">民生实事</div>
                    </div>

                    <div class="item hand" @click="$refs.yunChild.open('wsyd')">
                        <div class="item1">{{ saleOnlieNum }}<span class="danwei">(家)</span></div>
                        <div class="item2">网售药店</div>
                    </div>
                    <div class="item hand" @click="$refs.yunChild.open('jgda')">
                        <div class="item1">{{ jgda }}<span class="danwei">(件)</span></div>
                        <div class="item2">监管档案</div>
                    </div>
                    <div class="item" @click="$refs.yunChild.open('tsfw')">
                        <div class="item1">{{ tsfw }}<span class="danwei">(次)</span></div>
                        <div class="item2">特色服务</div>
                    </div>
                    <div class="item">
                        <div class="item1"> {{ monthNumber }}
                            <!--                          <IOdometer
                            class="iOdometer"
                            :value="weekNumber"
                            format="d"
                        ></IOdometer>-->
                            <span class="danwei">(人/次)</span>
                        </div>
                        <div class="item2">点击量(月)</div>
                    </div>

                    <div class="item" @click="$refs.childDialog.openSatisfaction('satisfaction')">
                        <div class="item1">99.8%</div>
                        <div class="item2">综合满意度</div>
                    </div>
                </div>
                <div style="height: 20px"></div>
                <div class="" v-if="isShowMap">
                    <yun-baidu @changeCity="changeCity" :height="653" :dialog-data="storeLevelList" :dialog-city="storeCity" :dialog-arr="storeLevelArr"></yun-baidu>
                </div>

            </div>
            <div class="body_right">
                <div class="body_right1">
                    <div class="title" @click="open('/yunWarning')">
                        <span class="jt"><img style="width:28px;height:28px" src="../assets/yun/home/jt.png" /> </span>
                        预警分析
                    </div>
                    <div class="yjfx" style="padding-right: 10px">
                        <!--                        <div class="left hand" @click="$refs.childDialog.openWarning('', 1)">
                            <div class="waring-echart" ref="waringEchart"></div>
                            <span class="left2">今日预警</span>
                        </div>-->
                        <div class="left">
                            <div style="width:280px;height:180px" ref="analyseline"></div>
                        </div>
                        <div class="right" style="margin-top: 19px;margin-left:2px">
                            <div class="item flex hand" @click="$refs.childDialog.openWarning('', 3)">
                                <div class="item1">累计预警</div>
                                <div class="item2 flex">
                                    <div class="item2-1" v-for="x in yjsMonth2">{{ x }}</div>
                                </div>
                            </div>
                            <div class="item flex" style="margin-top: 16px">
                                <div class="item1">累计整改</div>
                                <div class="item2 flex">
                                    <div class="item2-1" v-for="x in zzgNum2">{{ x }}</div>
                                </div>
                            </div>
                            <div class="item flex" style="margin-top: 16px">
                                <div class="item1">总整改率</div>
                                <div class="item2 flex">
                                    <div class="item2-1-ratio">
                                        {{ zzl }}
                                    </div>
                                </div>
                            </div>
                            <div class="item flex" style="margin-top: 16px">
                                <div class="item1">今日预警数</div>
                                <div class="item2 flex">
                                    <div class="item2-1">
                                        {{ total.yjsToday }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="body_right2">
                    <div class="title" @click="open('/yunRegulation')">
                        <span class="jt"><img style="width:28px;height:28px" src="../assets/yun/home/jt.png" /> </span>
                        精密智管
                    </div>
                    <div class="jmjg">
                        <div class="jmjg1 item flex">
                            <div style="" @click="$refs.childDialog.openJrfxcz()">
                                今日风险处置▶
                            </div>
                            <div style="width: 83px;text-align: right;font-size:30px" class="huang">
                                <!-- {{fxczJia}} -->
                                1
                            </div>
                            <div style="margin-left: 30px;color: #00d7fe" class="flex-vc"
                                @click="$refs.childDialog.openTan(2)">
                                <!--                                <img src="../assets/yun/home/tan.png" />-->
                                累计风险处置▶
                            </div>
                            <div style="width: 87px;text-align: right;font-size:30px;color:#ffc038" class="bai">
                                {{ fxczJia2 }}
                            </div>
                        </div>
                        <div class="jmjg2 item flex" style="margin-top: 20px" @click="$refs.yunChild.open('mhj')">
                            <div style="flex: 1">含麻黄碱药品销售情况</div>
                            <div style="text-align: right;font-size:30px;padding-right: 10px;" class="bai">
                                {{ mhj.teshu }}
                            </div>
                        </div>
                        <div class="jmjg3 item flex" style="margin-top: 13px" @click="$refs.yunChild.open('xfj')">
                            <div style="flex: 1">含兴奋剂药品销售情况</div>
                            <div class="bai" style="padding-right: 10px;font-size:30px">
                                {{ cint(mhj.xifenji) }}
                                <!--                                <IOdometer
                                    class="iOdometer"
                                    :value="numberArr"
                                    format="d"
                                    :duration="0"
                                ></IOdometer>-->
                            </div>
                        </div>
                        <div class="jmjg4 item flex" style="margin-top: 20px">
                            <div style="flex:1">药品购销总体情况▶</div>
                            <div class="bai" style="font-size:30px;text-align: right;padding-right: 10px;">
                                <span style="margin-right: 20px" @click="$refs.childDialog.openBuy()"><span
                                        class="gouxiao">购</span>{{ purchaseData.total }}</span>
                                <span @click="$refs.childDialog.openSale()"><span
                                        class="gouxiao">销</span>{{ saleData.total }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="body_right3">
                    <div class="title" @click="open('/yunAssess')">
                        <span class="jt"><img style="width:28px;height:28px" src="../assets/yun/home/jt.png" /> </span>
                        考核评级
                    </div>
                    <div class="content-title">
                        <div class="comomnt " :class="{ active: current == 2 }" @click="current = 2">
                            <!-- <img
                            style="width:25px;height25px;margin-right:10px"
                            :src="current == 2 ? imgList[7] : imgList[6]"
                            alt=""
                        /> -->
                            <span>药店分级</span>
                        </div>
                        <div class="comomnt " :class="{ active: current == 1 }" @click="current = 1">
                            <!-- <img
                                style="width:25px;height25px;margin-right:10px"
                                :src="current == 1 ? imgList[3] : imgList[0]"
                                alt=""
                            /> -->
                            <span>部门评价</span>
                        </div>

                        <div class="comomnt" :class="{ active: current == 4 }" @click="current = 4">
                            <!-- <img
                            style="width:25px;height25px;margin-right:10px"
                            :src="current == 4 ? imgList[5] : imgList[2]"
                            alt=""
                        /> -->
                            <span>药师考核</span>
                        </div>
                        <div class="comomnt assese-title" :class="{ active: current == 3 }" @click="current = 3">
                            <!-- <img
                                style="width:25px;height25px;margin-right:10px"
                                :src="current == 3 ? imgList[4] : imgList[1]"
                                alt=""
                            /> -->
                            <span>签约医生考核</span>
                        </div>

                    </div>
                    <div class="content khpj" v-if="current == 1">
                        <div class="item item1" @click="$refs.childDialog.openBmpj(1)">
                            <div>5</div>
                            <div>优秀</div>
                        </div>
                        <div class="item item2" @click="$refs.childDialog.openBmpj(2)">
                            <div>1</div>
                            <div>良好</div>
                        </div>
                        <div class="item item3" @click="$refs.childDialog.openBmpj(3)">
                            <div>2</div>
                            <div>合格</div>
                        </div>
                    </div>
                    <div class="content" v-if="current == 2">
                        <div class="level" @click="$refs.childDialog.openlevel('A级')">
                            <span style="color:#19D12A">{{ arrA }}</span>
                            <span>A级</span>
                            <div class="level-left"></div>
                        </div>
                        <div class="level" @click="$refs.childDialog.openlevel('B级')">
                            <span style="color:#087ED7">{{ arrB }}</span>
                            <span>B级</span>
                            <div class="level-center"></div>
                        </div>
                        <div class="level" @click="$refs.childDialog.openlevel('C级')">
                            <span style="color:#B7500E">{{ arrC }}</span>
                            <span>C级</span>
                            <div class="level-right"></div>
                        </div>
                        <div class="level level-last" @click="$refs.childDialog.openlevel('D级')">
                            <span style="color:#C50E24">{{ arrD }}</span>
                            <span>D级</span>
                        </div>
                    </div>
                    <div class="content" v-if="current == 3">
                        <div class="star star-left">
                            <span>289</span>
                            <span>★★★★★</span>
                        </div>
                        <div class="star star-center">
                            <span>22</span>
                            <span>★★★★</span>
                        </div>
                        <div class="star star-right">
                            <span>6</span>
                            <span>★★★</span>
                        </div>
                    </div>
                    <div class="content" v-else-if="current == 4">
                        <div class="assess assess-left">
                            <span>198</span>
                            <span>★★★★★</span>
                        </div>
                        <div class="assess assess-center">
                            <span>57</span>
                            <span>★★★★</span>
                        </div>
                        <div class="assess assess-right">
                            <span>3</span>
                            <span>★★★</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <yun-dialog ref="childDialog" />
        <yun-child ref="yunChild" />
    </div>
</template>

<script>
import yunChild from "@/views/yunChild";
import yunHeader from "@/views/yunHeader";
import yunDialog from "@/views/yunDialog";
import yizhan from "@/assets/data/yizhan.json";
import doctor from "@/assets/data/doctor.json";
import IOdometer from "vue-odometer";
import "odometer/themes/odometer-theme-default.css";
import {
    visitor,
    todayWarning,
    task,
    saleList,
    yunStore,
    purchaseList,
    screenCount,
    storeList,
    mhjSaleNum,
    disposalNum,
    messageNum,
    saleOnline,
    storeCount,mountainIsland, pharmacyServiceStation, storeDelivery, chestNum,
    groupStoreLevelApi,
    getHomeCenterTopApi
} from "../api/yaojian";
import moment from "moment";
import map from "@/assets/data/map.json";
import yunBaidu from "@/views/components/yunBaidu";
import img1 from "../assets/yun/home/one-1.png";
import img2 from "../assets/yun/home/one-2.png";
import img3 from "../assets/yun/home/one-3.png";
import img4 from "../assets/yun/home/one-4.png";
import img5 from "../assets/yun/home/one-5.png";
import img6 from "../assets/yun/home/one-6.png";
import img7 from "../assets/yun/home/assess.png";
import img8 from "../assets/yun/home/assess1.png";
import * as echarts from "echarts";
export default {
    name: "yunHome",
    components: { yunHeader, yunDialog, yunChild, yunBaidu, IOdometer, },
    data() {
        return {
            isShowMap: false,//是否显示地图
            monthNumber: 6173,
            locId: localStorage.id,
            ssjdNum: 0,//上山进岛数量
            ysfwzNum: 0,//药事服务站数量
            wddsNum: 0,//网订店送数量
            jgda: 0,//监管档案
            storeCity: {},
            zzgNum: 0,//总整改数
            saleOnlieNum: 0,//网络销售药店家数
            tsfw: 0,//特色服务数
            mhj: {
                teshu: 0,
                xifenji: 0
            },
            analyse: null,
            level1: 3629,
            level2: 55,
            level3: 9,
            nofound: 9,
            fxczJia: 0,
            fxczJia2: 0,
            fxczList: {},
            saleData: {},
            purchaseData: {},
            pharmacistData: {
                total: 0
            },
            doctor: doctor,
            postPrescription: {
                page: 1,
                count: 5,
                isPrescription: 1,
                hospital: "1",
                startTime: moment()
                    .add(-7, "day")
                    .format("yyyy-MM-DD 00:00:00"),
                endTime: moment().format("yyyy-MM-DD 23:59:59"),
                type: 1,
            },
            map: map,
            yjsMonth: 0,
            searchShow: false,
            keyword: "",
            restaurants: [],
            mapZt: 1,
            legendZt: 4,
            page: "yunHome",
            fwzl: 315478,
            total: {
                prescriptionTotal: 0,
                stockTotal: 0,
                yjsToday: 0,
                fxcz: {},
                yd: 0
            },
            visitorData: {},
            visitorData2: [],
            storePersonHs: 0,
            storePersonHsData: {},
            disposalData1: {}, //自主整改
            disposalData2: {}, //责令整改
            today: [moment().format("yyyy-MM-DD 00:00:01"), moment().format("yyyy-MM-DD 23:59:59")],
            arrDate: [
                moment().format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("week")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("month")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("quarter")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("year")
                    .format("yyyy-MM-DD 00:00:00"),
            ],
            cflzs: 0,
            yizhan: yizhan,
            yunStore: {},
            serveNUmber: 0,
            numList: 0,
            timer: null,
            onlineNumber: 3345,
            consultNumber: 2778,
            examiniNumber: 39572,
            weekNumber: 7096,
            numberArr: 0,
            imgList: [img1, img2, img3, img4, img5, img6, img7, img8],
            current: 2,
            yellowCode: {},
            redCode: {},
            arrA: 0,
            arrB: 0,
            arrC: 0,
            arrD: 0,
            storeLevelArr: null,
            //预警数最近4个月
            yjs: [],
            storeLevelList: [],
            // 存续店铺数量
            storeCount: 0,
            // 执业药师数量
            pharmacistDataCount: 0
        };
    },
    computed: {
        msssNum() {//民生实事数量
            let num = this.ssjdNum + this.ysfwzNum + this.wddsNum
            return num
        },
        treeLevelTotal() {
            return this.level1 + this.level2 + this.level3 + this.nofound
        },
        //今年累积访客异常数
        sumTotal() {
            const res1 = this.visitorData2[0]?.list
            const res2 = this.visitorData2[1]?.list
            let newAarry = []
            if (res1 && res2) {
                newAarry = [...res1, ...res2]
            }
            return newAarry.length
        },
        yjsToday2() {
            //今日自主整改数
            let zzzg = this.total.yjsToday;
            if (!zzzg) {
                zzzg = 0;
            }
            let a = zzzg.toString().split("");
            return a;
        },
        fwzl2() {
            let a = this.fwzl.toString().split("");
            return a;
        },
        yjsMonth2() {
            let a = this.yjsMonth.toString().split("");
            return a;
        },
        zzgNum2() {
            let a = this.zzgNum.toString().split("");
            return a;
        },
        zzl() {
            let a = this.$x.divide(this.zzgNum, this.yjsMonth) * 100;
            a = this.$x.round(a, 2)
            return a + "%";
        },
        // 药师服务量
        // yizhanCi() {
        //     let num = 0;
        //     this.yizhan.forEach((x) => {
        //         num += parseInt(x.a4);
        //     });
        //     this.numList = num;
        //     return num;
        // },
    },
    created() {
        this.reset();
    },
    mounted() {
        this.numList = this.yizhanCi;
        this.init();
    },
    methods: {
        changeCity(e) {
            console.log(e, "shuaxin")
            this.init()
        },
        init() {
            /* this.getChest()//智能药箱数量
            this.getMonthClick()//特色服务数量
            this.getSsjd()//上山进岛数量
              this.getWdds()//网订店送店铺
               this.getYsfw()//药事服务数量*/
            /*  this.getData();
             this.getMhj()
             this.getPharmacist();
             this.allChuzhi()
             this.getSale()
             this.loadStoreLevel()
             this.getYj()
             this.getZg()*/
            this.loadFirst()
        },
        loadFirst() {
            this.getHomeCenterTopData()
            // Promise.all([this.getMonthClick(), this.getWdds(), this.getYsfw(), this.getSsjd(), this.getChest()]).then(res => {
            //     this.loadSecond()
            // })
            this.loadSecond()
        },
        loadSecond() {
            Promise.all([this.getData()]).then(() => {
                this.loadThird()
            })
        },
        loadThird() {
            Promise.all([this.getChest(), this.getMhj(), this.getPharmacist(), this.allChuzhi(), this.getSale()]).then(() => {
                this.loadFourth()
            })
        },
        loadFourth() {
            Promise.all([this.loadStoreLevel(), this.getYj(), this.getZg()]).then(() => {
                this.isShowMap = true
            })
        },
        /** 获取数据 */
        async getHomeCenterTopData() {
            getHomeCenterTopApi({}).then(res => {
                if (res.code == 200) {
                    this.monthNumber = res.data.hits
                    this.storeCount = res.data.storeCount
                    this.pharmacistDataCount = res.data.pharmacistCount
                    this.ssjdNum = Number(res.data.mountainIslandCount)
                    this.ysfwzNum = Number(res.data.pharmacyServiceStationCount)
                    this.wddsNum = Number(res.data.storeDeliveryCount)
                    this.jgda = Number(res.data.taskCount)
                    this.tsfw = Number(res.data.nonprofitApplicationCount)
                }
            })
        },
        /** 点击量 */
        async getMonthClick() {
            // let data = { startTime: this.$moment().format("YYYY-MM-01 00:00:00"), endTime: this.$moment().endOf('month').format("YYYY-MM-DD 23:59:59") }
            // let rs = await clickNumber(data)
            // if (rs.code == 200) {
            //     this.monthNumber = rs.data
            // }
        },
        /** 药箱数据 */
        async getChest() {
            let data = { page: 1, size: 10, startTime: this.$moment().format("YYYY-01-01 00:00:00"), endTime: this.$moment().endOf('year').format("YYYY-MM-DD 23:59:59") }
            let rs = await chestNum(data)
            if (rs.code == 200) {
                this.serveNUmber = parseInt(rs.data)
            }
        },
        /** 预警消息数据 */
        async getYj() {
            //今日预警数
            let rs = await todayWarning({ today: 1 })
            if (rs.code == 200) this.total.yjsToday = rs.data;

            //本月预警数
            let rs2 = await messageNum({ page: 1, count: 10, start: "2021-01-01 00:00:00", end: this.today[1] })
            if (rs2.code == 200) this.yjsMonth = rs2.data.total;

            let yearmonthStart1 = this.$moment().add(-3, 'month').startOf('month').format("yyyy-MM-DD")
            let yearmonthEnd1 = this.$moment().add(-3, 'month').endOf('month').format("yyyy-MM-DD")
            let yearmonthStart2 = this.$moment().add(-2, 'month').startOf('month').format("yyyy-MM-DD")
            let yearmonthEnd2 = this.$moment().add(-2, 'month').endOf('month').format("yyyy-MM-DD")
            let yearmonthStart3 = this.$moment().add(-1, 'month').startOf('month').format("yyyy-MM-DD")
            let yearmonthEnd3 = this.$moment().add(-1, 'month').endOf('month').format("yyyy-MM-DD")
            let yearmonthStart4 = this.$moment().add(-0, 'month').startOf('month').format("yyyy-MM-DD")
            let yearmonthEnd4 = this.$moment().add(-0, 'month').endOf('month').format("yyyy-MM-DD")

            let rs4 = await todayWarning({ today: 1, startTime: yearmonthStart1, endTime: yearmonthEnd1 })
            if (rs4.code == 200) this.yjs[0] = rs4.data;
            let rs5 = await todayWarning({ today: 1, startTime: yearmonthStart2, endTime: yearmonthEnd2 })
            if (rs5.code == 200) this.yjs[1] = rs5.data;
            let rs6 = await todayWarning({ today: 1, startTime: yearmonthStart3, endTime: yearmonthEnd3 })
            if (rs6.code == 200) this.yjs[2] = rs6.data;
            let rs7 = await todayWarning({ today: 1, startTime: yearmonthStart4, endTime: yearmonthEnd4 })
            if (rs7.code == 200) this.yjs[3] = rs7.data;
            this.analyseEhart()
        },
        /** 整改数据 */
        async getZg() {
            let rs2 = await disposalNum()
            this.zzgNum = rs2.data.total
        },
        back() {
            this.$store.commit('SET_cityId', localStorage.id)
            this.init()
        },
        // 风险趋势分析折线图
        analyseEhart() {
            this.analyse = echarts.init(this.$refs.analyseline);
            //获取月份
            let monthArr = []
            let nowMonth = this.$moment().format("MM")
            for (let i = nowMonth; i >= nowMonth - 3; i--) {
                let m = i
                if (m < 1) {
                    m += 12
                }
                monthArr.unshift(m + "月")
            }
            let dataMonth = monthArr
            let data = this.yjs
            const option = {
                title: {
                    text: "风险趋势",
                    left: '3%',
                    textStyle: { color: '#fff' }
                },
                grid: {//设置柱状图位置   上下左右距离也可以用   y y2 x x2 表示 可以用百分比表示也可以直接数字  例如 x:20\
                    top: '16%',
                    left: '3%',
                    right: '4%',
                    bottom: '0%',
                    containLabel: true  // 表示grid 区域是否包含坐标轴的刻度标签 常用于『防止标签溢出』的场景，标签溢出指的是，标签长度动态变化时，可能会溢出容器或者覆盖其他组件
                },
                xAxis: {
                    type: "category",
                    data: dataMonth,
                    axisLabel: {
                        fontSize: 14,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                },
                yAxis: {
                    axisLine: {
                        show: true
                    },
                    type: "value",
                    axisLabel: {
                        fontSize: 12,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                    splitLine: {
                        show: false,//不显示分割线
                        lineStyle: {
                            color: "#081d4b",
                        },
                    },
                },
                series: [
                    {
                        data: data,
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            color: " #00D7FE",
                        },
                        areaStyle: {
                            normal: {
                                // 设置折线图颜色渐变
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 1, color: "#00D7FE " },
                                    { offset: 0, color: "#07244a" },
                                ]),
                            },
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                    {
                        data: data,
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            color: " #00D7FE",
                        },
                        areaStyle: {
                            normal: {
                                // 设置折线图颜色渐变
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 1, color: "#00D7FE " },
                                    { offset: 0, color: "#07244a" },
                                ]),
                            },
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                    {
                        data: data,
                        type: "line",
                        smooth: true,

                        lineStyle: {
                            color: " #00D7FE",
                        },
                        areaStyle: {
                            normal: {
                                // 设置折线图颜色渐变
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 1, color: "#00D7FE " },
                                    { offset: 0, color: "#07244a" },
                                ]),
                            },
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                    {
                        data: data,
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            color: " #00D7FE",
                        },
                        areaStyle: {
                            normal: {
                                // 设置折线图颜色渐变
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: "#00D7FE  " },
                                    { offset: 0, color: "#07244a" },
                                ]),
                            },
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                ],
            };
            this.analyse.setOption(option);
        },
        openYizhan(t = 1) {
            this.$refs.childDialog.openYizhan(t)
        },
        loadStoreLevel() {//标注等级店铺
            const _this = this
            groupStoreLevelApi({}).then(res => {
                _this.storeLevelArr = res.data
                Object.keys(res.data).forEach(key => {
                    if (key === 'A') {
                        _this.arrA = res.data['A'].cnt | 0
                    } else if (key === 'B') {
                        _this.arrB = res.data['B'].cnt | 0
                    } else if (key === 'C') {   
                        _this.arrC = res.data['C'].cnt | 0
                    } else if (key === 'D') {
                        _this.arrD = res.data['D'].cnt | 0
                    }
                })
            })
            // storeLevel(post).then(res => {
            //     this.arrA = res.data.list.filter(x => { return x.level == "A" })
            //     this.arrB = res.data.list.filter(x => { return x.level == "B" })
            //     this.arrC = res.data.list.filter(x => { return x.level == "C" })
            //     this.arrD = res.data.list.filter(x => { return x.level == "D" })
            //     this.storeLevelList = res.data.list
            // })
        },
        /** 今日访客数据 */
        todayVisitor() {
            // 访客人数
            visitor({
                page: 1,
                count: 200,
                startTime: this.today[0],
                endTime: this.today[1],
            }).then((rs) => {
                this.visitorData = rs.data;
            });
            this.$refs.childDialog.openJrfks()
        },
        /** 今日处置数据 */
        todayChuzhi() {
            let post = {
                page: 1, count: 1000,
                startTime: this.today[0],
                endTime: this.today[1]
            }
            task(post).then(rs => {
                this.fxczList = rs.data
                //   let arr=this.fxczList.list.map(x=>x.storeName)
                //   arr=Array.from(new Set(arr));
                this.fxczJia = this.fxczList.list.length
            })
        },
        /** 全部处置数据 */
        allChuzhi() {
            let post = {
                page: 1, count: 10000,
                status: 30
            }
            task(post).then(rs => {
                //   let arr=rs.data.list.map(x=>x.storeName)
                //  arr =Array.from(new Set(arr));
                this.fxczJia2 = rs.data.list.length
            })
        },
        async getMhj() {
            let rs = await mhjSaleNum()
            if (rs.code == 200) this.mhj = rs.data
        },
        /** 销售和采购数量 */
        getSale() {
            let post = {
                page: 1,
                count: 5,
                startTime: this.today[0],
                endTime: this.today[1],
                type: 1
            }
            saleList(post).then(rs => {
                this.saleData = rs.data
            })
            //获取采购数量
            purchaseList(post).then(rs => {
                this.purchaseData = rs.data
            })
        },
        getPharmacist() {
            // pharmacistData({ page: 1, count: 5, status: 20 }).then((rs) => {
            //     this.pharmacistData = rs.data;
            //     this.pharmacistData.total = parseInt(rs.data.total);
            // });
        },
        reset() {
            setInterval(() => {
                this.onlineNumber++;
                this.weekNumber++;
            }, 30000);
            setInterval(() => {
                this.consultNumber++;
            }, 30000);
            setInterval(() => {
                this.examiniNumber++;
            }, 15000);
        },

        getYunStore() {
            yunStore().then((rs) => {
                this.yunStore = rs.data;
            });
        },
        start() {
        },
        go(url) {
            this.$router.push(url + "&url=/yunHome");
        },
        open(url) {
            this.$router.push(url);
        },
        cint(i) {
            return parseInt(i)
        },
        getMsys() {

        },
        async getSsjd() {//上山进岛
            let rs7 = await mountainIsland({ page: 1, count: 10 })
            if (rs7.code == 200) this.ssjdNum = rs7.data.total
        },
        async getWdds() {//网订店送店铺
            let rs9 = await storeDelivery({ page: 1, count: 10 })
            if (rs9.code == 200) this.wddsNum = rs9.data.total
        },
        async getYsfw() {//药事服务站点
            let rs8 = await pharmacyServiceStation({ page: 1, count: 10 })
            if (rs8.code == 200) this.ysfwzNum = rs8.data.total
        },
        async getData() {
            //药店数,执业药师数
            let rs = await screenCount()
            this.total.yd = rs.data.store;

            let rs3 = await saleOnline({ type: 0, tag: 71, page: 1, count: 10 })
            this.saleOnlieNum = rs3.data.total

            // let rs6 = await regulatoryTask({ page: 1, count: 10 })
            // if (rs6.code == 200) this.jgda = rs6.data.total
            // let rs4 = await nonprofitApplication({ status: 20, page: 1, count: 10 })
            // if (rs4.code == 200) this.tsfw = rs4.data.total

            let rs5 = await storeCount({})
            if (rs5.code == 200) this.storeCity = rs5.data

        },
        mapOver(t) {
            this.mapZt = t;
            let o = this.$refs["xz" + t];
            o.style.opacity = "1";
            o.style.transform = "scale(1.1)";
            // o.style.cssText="width:535px;height:535px";
        },
        mapOut(t) {
            this.mapZt = t;
            let o = this.$refs["xz" + t];
            o.style.opacity = "0";
            o.style.transform = "scale(1)";
        },
        //搜素代码----------------------------------------start
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1;
            };
        },
        async loadAll() {
            let arr;
            let that = this;
            let rs = await storeList()
            arr = rs.data.data;
            for (let i in arr) {
                arr[i].value = arr[i].name;
            }
            that.restaurants = arr;
            /*       axios.get("/screen/store/list", {}).then(function(response) {
                       arr = response.data.data;
                       for (let i in arr) {
                           arr[i].value = arr[i].name;
                       }
                       that.restaurants = arr;
                   });*/
        },
        handleSelect(item) {
            let url = this.$route.path;
            this.$router.push(`/yunStore?id=${item.id}&name=${item.name}&new=1&url=${url}`);
            //this.$refs.children.handleSelect(item)
        },
        //搜素代码----------------------------------------end

        //今日预警图表
        waringEchart() {
           
        },
    },
};
</script>
<style lang="less" scoped>
.gouxiao {
    font-size: 12px;
    margin-right: 3px;
    color: #FBD502
}

.ysfwBlock {
    margin-top: 10px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .jian {
        margin-right: 20px;
    }

    .item {
        border-radius: 4px;
        padding: 10px;
        border: 1px solid #0E6B83;
        margin-top: 40px;
        width: 43%;
        display: flex;
        align-items: center;

        img {
            width: 60px;
            height: 60px;
        }

        .label {
            font-size: 22px;
            margin-right: 9px
        }

        .value {
            text-align: right;
            width: 88px;
            font-size: 21px;
            color: #fff;
        }
    }
}

.danwei {
    font-size: 14px
}

.ico:hover {
    .pop {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 10px;
        width: 200px;
        color: #000;
        display: block;
        z-index: 10001;
        position: absolute;
        top: -64px;
    }
}

.ico {
    .dwt {
        z-index: 10000000;
    }

    .pop {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 10px;
        width: 200px;
        color: #000;
        display: block;
        z-index: 10001;
        position: absolute;
        top: -64px;
        display: none;
    }
}
</style>
<style scoped>
.flex-vc {
    display: flex;
    align-items: center;
}

.rlv {
    position: relative;
}

* {
    user-select: none;
}

.flex {
    display: flex;
}

.iOdometer {
    font-size: 30px;
}

/*地图开始*/
.icos .ico {
    cursor: pointer;
}

.icos {
    position: absolute;
    z-index: 10;
}

.legend .legend_item {
    display: flex;
    align-items: center;
    height: 40px;
}

.legend .legend_item1 {
    margin-left: 10px;
    width: 14px;
    height: 14px;

    border-radius: 2px;
}

.legend .legend_item2 {
    margin-left: 10px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
}

.legend .legend_item3 {
    margin-left: 10px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffc038;
}

.legend {
    right: -50px;
    top: 0;
    position: absolute;
    width: 167px;
    height: 180px;
    background: rgba(0, 53, 103, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#Map area {
    outline: none;
}

.searchBar {
    margin-left: 20px;
    margin-top: 5px;
}

>>>.el-input__icon {
    line-height: 30px;
}

.skey>>>.el-input__inner {
    border: 1px solid #17406e;
    background-color: #111f3e;
    border-radius: 25px;
    width: 351px;
    color: #28c0ea;
    font-size: 16px;
    opacity: 0.7;
    height: 30px;
    line-height: 30px;
}

.search {
    position: absolute;
    left: 0;
    top: 10px;
    z-index: 101;
}

.sbtn {
    font-size: 30px;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    position: absolute;
    width: 50px;
    height: 50px;
    background: rgba(2, 119, 250, 0.4);
    border-radius: 10px;
    top: 0px;
    left: -53px;
}

.ditu .item {
    pointer-events: none;
    transition: all 0.2s;
    background-size: cover;
}

.ditu .item1 {
    background-image: url("../assets/yun/map/1.png");
    width: 255px;
    height: 173px;
    left: 140px;
    top: 6px;
    opacity: 0;
}

.ditu .item2 {
    background-image: url("../assets/yun/map/2.png");
    width: 228px;
    height: 168px;
    left: 99px;
    top: 39px;
    opacity: 0;
}

.ditu .item3 {
    background-image: url("../assets/yun/map/3.png");
    width: 118px;
    height: 171px;
    left: 345px;
    top: 24px;
    opacity: 0;
}

.ditu .item4 {
    background-image: url("../assets/yun/map/4.png");
    width: 221px;
    height: 115px;
    left: 76px;
    top: 119px;
    opacity: 0;
}

.ditu .item5 {
    background-image: url("../assets/yun/map/5.png");
    width: 244px;
    height: 127px;
    left: 25px;
    top: 196px;
    opacity: 0;
}

.ditu .item6 {
    background-image: url("../assets/yun/map/6.png");
    width: 135px;
    height: 140px;
    left: 12px;
    top: 295px;
    opacity: 0;
}

.ditu .item7 {
    background-image: url("../assets/yun/map/7.png");
    width: 139px;
    height: 167px;
    left: 85px;
    top: 303px;
    opacity: 0;
}

.ditu .item8 {
    background-image: url("../assets/yun/map/8.png");
    width: 113px;
    height: 152px;
    left: 188px;
    top: 283px;
    opacity: 0;
}

.ditu .item9 {
    background-image: url("../assets/yun/map/9.png");
    width: 149px;
    height: 173px;
    left: 221px;
    top: 230px;
    opacity: 0;
}

.ditu .item10 {
    background-image: url("../assets/yun/map/10.png");
    width: 101px;
    height: 191px;
    left: 285px;
    top: 179px;
    opacity: 0;
}

.ditu .item11 {
    background-image: url("../assets/yun/map/11.png");
    width: 111px;
    height: 171px;
    left: 351px;
    top: 183px;
    opacity: 0;
}

.ditu .item12 {
    background-image: url("../assets/yun/map/12.png");
    width: 143px;
    height: 163px;
    left: 383px;
    top: 172px;
    opacity: 0;
}

.ditu .item13 {
    background-image: url("../assets/yun/map/13.png");
    width: 97px;
    height: 127px;
    left: 453px;
    top: 283px;
    opacity: 0;
}

.ditu .item14 {
    background-image: url("../assets/yun/map/14.png");
    width: 136px;
    height: 203px;
    left: 339px;
    top: 327px;
    opacity: 0;
}

.ditu .item15 {
    background-image: url("../assets/yun/map/15.png");
    width: 168px;
    height: 146px;
    left: 221px;
    top: 378px;
    opacity: 0;
}

.ditu {
    position: relative;
    width: 560px;
    height: 535px;
    margin: 0 auto;
}

/*地图结束*/
.khpj {
    display: flex;
}

.pjd {
    background-image: url("../assets/yun/home/khpj4.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.khpjCenter .item {
    width: 110px;
    text-align: center;
}

.khpjCenter .item1 {}

.khpjCenter .item1-1 {
    font-size: 26px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
}

.khpjCenter .item1-2 {
    font-size: 18px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
}

.khpjCenter .item2 {}

.khpjCenter {
    width: 220px;
    display: flex;
    flex-wrap: wrap;
}

.khpj .left .item {
    width: 120px;
    height: 40px;
    display: flex;
}

.khpj .left .item1 {
    font-size: 22px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #00d7fe;
    flex: 1;
}

.khpj .left .item2 {}

.khpj .left .item2-1 {
    width: 35px;
    height: 40px;
    background: rgba(9, 31, 110, 0.5);
    border: 1px solid #0277fa;
    border-radius: 2px;
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffc038;
    text-align: center;
}

.khpj .left {
    margin-left: 33px;
    margin-top: 26px;
}

.khpjRight .item1 {
    font-size: 22px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #00d7fe;
    flex: 1;
}

.khpjRight .item2-1 {
    width: 35px;
    height: 40px;
    background: rgba(9, 31, 110, 0.5);
    border: 1px solid #0277fa;
    border-radius: 2px;
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffc038;
    text-align: center;
}

.khpjRight .item {
    width: 180px;
    height: 40px;
    display: flex;
}

.yjfx .right .item2 {}

.yjfx .right .item2-1 {
    margin-right: 5px;
    width: 35px;
    height: 33px;
    background: rgba(9, 31, 110, 0.5);
    border: 1px solid #0277fa;
    border-radius: 2px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffc038;
    text-align: center;
}

.yjfx .right .item2-1-ratio {
    margin-right: 5px;
    height: 33px;
    background: rgba(9, 31, 110, 0.5);
    border: 1px solid #0277fa;
    border-radius: 2px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffc038;
    text-align: center;
    padding: 0px 10px;
}

.yjfx .right .item1 {
    flex: 1;
    line-height: 45px;
    font-size: 19px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
}

.yjfx .right .item {
    width: 290px;
    height: 27px;
}

.yjfx .right {}

.yjfx {
    position: relative;
}

.yjfx .left {
    position: relative;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.waring-echart {
    width: 200px;
    height: 100%;
}

.yjfx .left .inside {
    position: absolute;
    left: 22px;
    top: 7px;
    width: 150px;
    height: 150px;
    background-image: url("../assets/yun/home/inside.png");
    background-size: 100% 100%;
    animation: move 10s linear infinite;
}

.yjfx .left .outside {
    position: absolute;
    top: 2px;
    left: 17px;
    width: 160px;
    height: 160px;
    background-image: url("../assets/yun/home/outside.png");
    background-size: 100% 100%;
    animation: dowm 15s linear infinite;
}

@keyframes dowm {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes move {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.yjfx .left1 {
    background-image: url("../assets/yun/home/jingbao3.gif");
    background-size: 110% 110%;
    background-position: center;
    mix-blend-mode: lighten;
    width: 150px;
    height: 150px;
    text-align: center;
    font-size: 59px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #f8f3f4;
    line-height: 143px;
}

.yjfx .left2 {
    position: absolute;
    left: 48%;
    bottom: -10px;
    transform: translateX(-50%);
    text-align: center;
    font-size: 24px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
}

.yjfx .item2 {}

.bai {
    color: #ffffff;
}

.huang {
    color: #ffc038;
}

.flex {
    display: flex;
}

.jmjg .item {
    line-height: 64px;
    font-size: 24px;
}

.jmjg {
    padding: 23px 20px 20px 35px;
}

.fyglBox .item1 {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #f0f0f0;
}

.fyglBox .item2 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
}

.fyglBox {
    display: flex;
    justify-content: space-between;
}

.fyglBox .item {
    width: 150px;
    height: 72px;
    background: #003567;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.body_right {
    width: 587px;
    margin-left: 16px;
}

.body_right1 {
    width: 590px;
    background-image: url("../assets/yun/home/right1.png");
    background-size: 100% 100%;
    height: 260px;
}

.body_right2 {
    margin-top: 15px;
    background-image: url("../assets/yun/home/right2.png");
    background-repeat: no-repeat;
    width: 590px;
    height: 412px;
}

.body_right3 {
    box-sizing: border-box;
    margin-top: 15px;
    background-image: url("../assets/yun/home/right3.png");
    width: 590px;
    height: 273px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
}

.content-title {
    width: 560px;
    margin: 15px auto;
    height: 35px;
    border: 1px solid #0094fa;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.evaluate-comont {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 20px;
}

.evaluate-comont span:first-child {
    position: relative;
    top: -20px;
    line-height: 31px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}

.evaluate-comont span:last-child {
    position: relative;
    top: -20px;
    line-height: 24px;
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
}

.evaluate-left {
    width: 108px;
    height: 145px;
    background-image: url("../assets/yun/home/5_bg.gif");
    background-size: 100% 100%;
    mix-blend-mode: lighten;
}

.evaluate-center {
    width: 108px;
    height: 145px;
    background-image: url("../assets/yun/home/7_bg.gif");
    background-size: 100% 100%;
    mix-blend-mode: lighten;
}

.evaluate-right {
    width: 108px;
    height: 145px;
    background-image: url("../assets/yun/home/6_bg.gif");
    background-size: 100% 100%;
    mix-blend-mode: lighten;
}

.content {
    flex: 1;
    display: flex;
    justify-content: space-around;
}

.level {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 26px;
}

.khpj .item {
    width: 161px
}

.khpj .item1 {
    background: url("../assets/yun/home/5_bg.gif");
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center;
    mix-blend-mode: lighten;
}

.khpj .item2 {
    background: url("../assets/yun/home/6_bg.gif");
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center;
    mix-blend-mode: lighten;
}

.khpj .item3 {
    background: url("../assets/yun/home/7_bg.gif");
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center;
    mix-blend-mode: lighten;
}

.khpj .item div:first-child {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;

}

.khpj .item div:nth-child(2) {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    position: relative;
    top: 10px;
    text-align: center;
    margin-top: 53px;

}

.level span:first-child {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;


}

.level span:nth-child(2) {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    position: relative;
    top: 10px
}

.level-left {
    width: 135px;
    height: 19px;
    background-image: url('../assets/yun/home/11.png');
    background-size: 100% 100%;
}

.level-center {
    width: 135px;
    height: 19px;
    background-image: url('../assets/yun/home/12.png');
    background-size: 100% 100%;
}

.level-right {
    width: 135px;
    height: 19px;
    background-image: url('../assets/yun/home/13.png');
    background-size: 100% 100%;
}

.level-last {
    width: 125px;
    height: 103px;
    background-image: url('../assets/yun/home/14.png');
    background-size: 100% 100%;
    margin-top: 25px;
}

.assess {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.assess span:first-child {
    position: relative;
    top: -20px;
    line-height: 31px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}

.assess span:last-child {
    position: relative;
    top: 30px;
    line-height: 22px;
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffd485;
}

.assess-left {
    width: 162px;
    height: 72px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../assets/yun/home/2.png");
}

.assess-center {
    width: 162px;
    height: 72px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../assets/yun/home/3.png");
}

.assess-right {
    width: 162px;
    height: 72px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../assets/yun/home/4.png");
}

.star-left {
    width: 179px;
    height: 91px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../assets/yun/home/9.png");
}

.star {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.star span:first-child {
    line-height: 31px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}

.star span:last-child {
    position: relative;
    top: 40px;
    line-height: 22px;
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffd485;
}

.star-center {
    width: 179px;
    height: 91px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../assets/yun/home/8.png");
}

.star-right {
    width: 179px;
    height: 91px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../assets/yun/home/10.png");
}

.content-title .active {
    background: #0094fa;
    color: #fff;
}

.comomnt {
    cursor: pointer;
    width: 130px;
    height: 100%;
    border-right: 1px solid #0094fa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0094fa;
}

.assese-title {
    width: 166px;
}

.ditu {
    height: 530px;
}

.ydfy {
    width: 100%;
    height: 145px;
    background-image: url("../assets/yun/home/centerXia.png");
    background-size: 100% 100%;
}

.hand {
    cursor: pointer;
}

.kuang .item1 {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffc038;
}

.kuang .item2 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
}

.kuang {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.kuang .item {
    margin-top: 9px;
    background-image: url("../assets/yun/home/center-kuang.png");
    background-size: 100% 100%;
    width: 160px;
    height: 83px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.zongNum {
    display: flex;
}

.zongNum .item {
    background-image: url("../assets/yun/home/num.png");
    width: 50px;
    height: 58px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffc038;
    text-align: center;
    line-height: 58px;
    margin-right: 8px;
}

.fwzl-1 {
    flex: 1;
    padding-left: 70px;
    line-height: 61px;
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
}

.fwzl {
    display: flex;
    height: 61px;
    width: 100%;
    background-image: url("../assets/yun/home/center_zong.png");
    background-repeat: no-repeat;
}

.body_center {
    width: 667px;
    margin-left: 15px;
}

.ysfw {}

.ysfw {
    padding-right: 30px;
    width: 515px;
    height: 64px;
}

.ysfw .item2 {
    flex: 1;
    margin-left: 15px;
    font-size: 24px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #00d7fe;
}

.ysfw .item3 {
    font-size: 24px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #fff;
}

.aqyy .item1 {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    margin-top: 0;
}

.aqyy .item2 {
    margin-top: 65px;
    width: 142px;
    height: 23px;
    font-size: 24px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #00d7fe;
    line-height: 45px;
}

.aqyy {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    padding: 0 20px;
}

.aqyy .item {
    width: 142px;
    background-image: url("../assets/yun/home/left_bg.gif");
    background-size: 100% 100%;
    mix-blend-mode: screen;
    background-repeat: no-repeat;
    background-position: center;
    height: 120px;
    text-align: center;
}

.body_left3 {
    margin-top: 15px;
    background-image: url("../assets/yun/home/left2.png");
    background-repeat: no-repeat;
    width: 590px;
    height: 352px;
    background-size: 100% 100%;
}

.body_left2 {
    margin-top: 15px;
    background-image: url("../assets/yun/home/left2.png");
    width: 590px;
    height: 274px;
}

.body_left1_2 .item1 {
    margin-top: 30px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}

.body_left1_2 .item2 {
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}

.body_left1_2 .item {
    background-image: url("../assets/yun/home/left1_box.gif");
    width: 130px;
    height: 154px;
    text-align: center;
    line-height: 200%;
}

.body_left1_2 {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    padding: 0 19px;
}

.bai {
    color: #ffffff;
}

.body_left1_1 {

    font-size: 26px;
    width: 550px;
    height: 75px;
    line-height: 75px;

    margin: 0 auto;
    margin-top: 18px;
}

.body_left-tree {
    flex: 1;
    padding: 0 20px;
    background: rgba(7, 35, 76, 0.5);
}

.title .jt {
    float: right;
    padding-right: 20px;
}

.title {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fff;
    padding-top: 9px;
    padding-left: 19px;
}

.body_left1 {
    background-image: url("../assets/yun/home/left1-1.png");
    width: 590px;
    height: 321px;
    background-size: 100% 100%;
}

.body {
    display: flex;
    color: #00d7fe;
    padding: 0 20px;
}

.menu_left {
    padding-top: 17px;
}

.menu_left .itemC {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
}

.menu_left {
    padding-left: 140px;
    position: absolute;
}

.menu_left .item {
    cursor: pointer;
    background-image: url("../assets/yun/home/m1.png");
    position: absolute;
    width: 175px;
    height: 48px;
}

.menu_left .item:hover {
    background-image: url("../assets/yun/home/m2.png");
    position: absolute;
    width: 175px;
    height: 48px;
}

.menu_right {
    padding-top: 17px;
}

.menu_right .itemC {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
}

.menu_right {
    right: 653px;
    position: absolute;
}

.menu_right .item {
    cursor: pointer;
    background-image: url("../assets/yun/home/m3.png");
    position: absolute;
    width: 175px;
    height: 48px;
}

.menu_right .item:hover {
    background-image: url("../assets/yun/home/m4.png");
    position: absolute;
    width: 175px;
    height: 48px;
}

.abs {
    position: absolute;
}

.storeTop {
    background-image: url("../assets/store/top.png");
    height: 76px;
    width: 1920px;
    text-align: center;

    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    position: relative;
}

.pageTitle {
    line-height: 76px;
    font-size: 30px;
}

.main {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    background-image: url("../assets/yun/home/bg.jpg");
    /*background-image: linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.3)), url('../assets/yun/home/bg.jpg');*/
}
</style>
